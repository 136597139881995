@import "/src/variables.module.scss";

.custom-table {
  table {
    tr {
      border-bottom: 1px solid $extra-light-grey-color;
    }
    td{
      &.newcustomertd{
        width: 75%;
      }
    }
    thead {
      @include media-breakpoint-down("xl") {
        top: -15px !important;
      }
      tr {
        th {
          font-size: 16px;
          font-weight: 500;
          color: $grey-color;
          padding: 11px 5px;
          border-bottom: 0;
          @include media-breakpoint-down("xxl") {
            font-size: 14px;
            padding: 10px 0;
          }
          @include media-breakpoint-down("xl") {
            padding: 7px 0;
          }
        }
      }
    }
    tbody {
      tr {
        &:last-child {
          border-bottom: 0;
          td {
            padding-bottom: 0;
          }
        }
        td {
          font-size: 16px;
          font-weight: 400;
          color: $grey-color;
          padding: 11px 5px;
          border-bottom: 0;
          @include media-breakpoint-down("xxl") {
            font-size: 14px;
            padding: 10px 0;
          }
          @include media-breakpoint-down("xl") {
            padding: 7px 0;
          }
        }
      }
    }
  }
}
