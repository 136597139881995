@import "/src/variables.module.scss";
.alarm-section {
  .card {
    margin-bottom: 24px;
    @include media-breakpoint-down("xxxl") {
      margin-bottom: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
