@import "../../../variables.module.scss";

#wrapper {
  margin-left: 300px;
  transition: all 0.5s ease;
  position: relative;
  @include media-breakpoint-down("xxxl") {
    margin-left: 270px;
  }
  @include media-breakpoint-down("xl") {
    margin-left: 240px;
  }
  @include media-breakpoint-down("lg") {
    margin-left: 200px;
  }
  #sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 0;
    width: 300px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: $white-color;
    transition: all 0.5s ease;
    border-right: solid $light-blue-color 1px;
    @include media-breakpoint-down("xxxl") {
      width: 270px;
    }
    @include media-breakpoint-down("xl") {
      width: 240px;
    }
    @include media-breakpoint-down("lg") {
      width: 200px;
    }
    .sidebar-brand {
      padding: 23px 20px;
      height: 80px;
      border-bottom: solid $light-blue-color 1px;
      @include media-breakpoint-down("xxxl") {
        height: 70px;
        padding: 15px 20px;
      }
      @include media-breakpoint-down("xxl") {
        height: 60px;
        padding: 10px 20px;
      }
      @include media-breakpoint-down("xl") {
        height: 50px;
      }
      img {
        width: 170px;
        height: auto;
        margin: auto;
        display: block;
        @include media-breakpoint-down("xxxl") {
          width: 150px;
        }
        @include media-breakpoint-down("xxl") {
          width: 120px;
        }
        @include media-breakpoint-down("xl") {
          width: 100px;
        }
      }
    }
    .sidebar-nav {
      padding: 26px 20px 0;
      list-style-type: none;
      height: calc(100vh - 140px);
      overflow-y: auto;
      @include media-breakpoint-down("xxxl") {
        height: calc(100vh - 130px);
      }
      @include media-breakpoint-down("xxl") {
        padding: 20px;
        height: calc(100vh - 118px);
      }
      @include media-breakpoint-down("xl") {
        height: calc(100vh - 105px);
      }
      &:hover {
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          background-color: $white-color;
        }
        &::-webkit-scrollbar-thumb {
          background: $light-blue-color;
        }
      }
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 10px;
        margin-top: 20px;
      }
      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 10px;
      }
      li {
        margin-bottom: 15px;
        @include media-breakpoint-down("xxxl") {
          margin-bottom: 12px;
        }
        @include media-breakpoint-down("xl") {
          margin-bottom: 5px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.active {
          a {
            text-decoration: none;
            color: $blue-color;
            background: $extra-light-blue-color;
            border-radius: 10px;
          }
        }
        a {
          text-decoration: none;
          color: $primary-color;
          font-weight: 500;
          font-size: 16px;
          padding: 13px 20px;
          @include media-breakpoint-down("xxxl") {
            font-size: 15px;
          }
          @include media-breakpoint-down("xl") {
            padding: 10px 20px;
            font-size: 13px;
          }
          span {
            margin-left: 13px;
            @include media-breakpoint-down("xl") {
              margin-left: 10px;
            }
          }
          img {
            width: 20px;
            height: 20px;
            @include media-breakpoint-down("xxxl") {
              width: 17px;
              height: 17px;
            }
            @include media-breakpoint-down("xl") {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
    .dashboard-logout {
      margin: 20px 20px 0px 40px;
      background-color: $white-color;
      width: 100%;
      display: block;
      font-size: 16px;
      font-weight: 500;
      color: $black-color;
      text-decoration: none;
      @include media-breakpoint-down("xxxl") {
        font-size: 15px;
      }
      @include media-breakpoint-down("xl") {
        font-size: 13px;
      }
      img {
        margin-right: 13px;
        margin-left: 0px;
        @include media-breakpoint-down("xxxl") {
          width: 17px;
          height: 17px;
        }
        @include media-breakpoint-down("xl") {
          width: 14px;
          height: 14px;
          margin-right: 10px;
        }
      }
    }
  }

  .navbar {
    background: $white-color;
    height: 80px;
    padding: 15px 30px;
    position: relative;
    z-index: 9;
    border-bottom: solid $light-blue-color 1px;
    position: fixed;
    left: 300px;
    right: 0;
    @include media-breakpoint-down("xxxl") {
      height: 70px;
      padding: 15px 20px;
      left: 270px;
    }
    @include media-breakpoint-down("xxl") {
      height: 60px;
      padding: 10px 20px;
    }
    @include media-breakpoint-down("xl") {
      left: 240px;
      height: 50px;
    }
    @include media-breakpoint-down("lg") {
      left: 200px;
    }
    .navbar-left {
      .rbt {
        &:before {
          content: "";
          position: absolute;
          background: url("/assets/images/search-icon.svg");
          width: 22px;
          height: 22px;
          top: 0;
          bottom: 0;
          right: 13px;
          margin: auto;
          z-index: 1;
          background-size: contain;
          @include media-breakpoint-down("xxl") {
            width: 18px;
            height: 18px;
          }
        }
        .form-control {
          width: 375px;
          padding: 13px 40px 13px 13px;
          background: $extra-light-blue-color;
          border: none;
          border-radius: 10px;
          color: $grey-color;
          border: 1px solid $light-blue-color;
          @include media-breakpoint-down("xxxl") {
            width: 300px;
            padding: 10px 35px 10px 13px;
          }
          @include media-breakpoint-down("xxl") {
            width: 250px;
          }
          @include media-breakpoint-down("xl") {
            width: 220px;
          }
          @include media-breakpoint-down("lg") {
            width: 170px;
          }
          &[aria-owns="SearchMainmenu"] {
            border-radius: 10px 10px 0 0;
            background-color: $white-color;
          }
          &:focus-visible {
            outline: 0;
          }
          &:focus {
            box-shadow: none;
          }
        }
        .rbt-menu {
          border-radius: 0 0 10px 10px;
          border: 1px solid $light-blue-color;
          border-top: none;
          background: $white-color;
          .dropdown-item {
            &:hover {
              background-color: $extra-light-blue-color;
            }
            @include media-breakpoint-down("xxxl") {
              font-size: 14px;
            }
          }
        }
      }
    }
    .navbar-right {
      .nav-item {
        margin-right: 50px;
        @include media-breakpoint-down("xxxl") {
          margin-right: 30px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .nav-notification {
        img {
          @include media-breakpoint-down("xxxl") {
            width: 22px;
          }
          @include media-breakpoint-down("xl") {
            width: 18px;
          }
        }
        .badge-bell {
          position: absolute;
          top: -12px;
          left: 12px;
          background: $blue-color;
          color: $white-color;
          font-size: 16px;
          border-radius: 25px;
          min-width: 25px;
          height: 25px;
          text-align: center;
          padding: 5px;
          font-weight: 500;
          line-height: 16px;
          @include media-breakpoint-down("xxxl") {
            font-size: 12px;
            min-width: 22px;
            height: 22px;
            line-height: 13px;
            top: -10px;
            left: 10px;
          }
          @include media-breakpoint-down("xl") {
            font-size: 10px;
            min-width: 18px;
            height: 18px;
            line-height: 8px;
            top: -7px;
            left: 7px;
          }
        }
      }
      .user-profile {
        .nav-user-detail {
          margin-left: 10px;
          h4 {
            font-size: 16px;
            color: $black-color;
            font-weight: 500;
            margin-bottom: 4px;
            line-height: 1.1;
            @include media-breakpoint-down("xxxl") {
              font-size: 14px;
              margin-bottom: 2px;
            }
            @include media-breakpoint-down("xl") {
              font-size: 12px;
            }
          }
          span {
            color: $grey-color;
            font-size: 14px;
            display: block;
            @include media-breakpoint-down("xxxl") {
              font-size: 13px;
            }
            @include media-breakpoint-down("xl") {
              font-size: 11px;
            }
          }
        }
        .profile-img {
          width: 46px;
          height: 46px;
          overflow: hidden;
          background-size: contain;
          background-position: center;
          background-color: $extra-light-blue-color;
          background-repeat: no-repeat;
          border: 2px solid $extra-light-blue-color;
          border-radius: 46px;
          @include media-breakpoint-down("xxxl") {
            width: 40px;
            height: 40px;
          }
          @include media-breakpoint-down("xxl") {
            width: 36px;
            height: 36px;
          }
          @include media-breakpoint-down("xl") {
            width: 32px;
            height: 32px;
          }
        }
      }
      .react-select-input {
        width: 150px;        
        .react-select-container {
          .react-select {
            &__control {
              min-height: 40px;
              border-radius: 100px;
              background: $extra-light-blue-color;
              border-color: $blue-color;
              padding: 2px 10px;
            }
          }
        }
      }
    }
  }
  .content-wrapper {
    background-color: $extra-light-blue-color;
    min-height: calc(100vh - 61px);
    padding: 110px 30px 0px;
    overflow-y: auto;
    @include media-breakpoint-down("xxxl") {
      min-height: calc(100vh - 51px);
      padding: 90px 20px 0;
    }
    @include media-breakpoint-down("xxl") {
      padding: 80px 20px 0;
    }
    @include media-breakpoint-down("xl") {
      padding: 70px 20px 0;
      min-height: calc(100vh - 38px);
    }
    @include media-breakpoint-down("lg") {
      min-height: calc(100vh - 45px);
    }
    .main-heading {
      margin-bottom: 30px;
      @include media-breakpoint-down("xxxl") {
        margin-bottom: 20px;
      }
      @include media-breakpoint-down("xl") {
        margin-bottom: 15px;
      }
      h2 {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 0;
        margin-right: 30px;
        @include media-breakpoint-down("xxxl") {
          font-size: 18px;
        }
        @include media-breakpoint-down("xxl") {
          font-size: 16px;
        }
        @include media-breakpoint-down("xl") {
          font-size: 14px;
          margin-right: 20px;
        }
        span {
          background-color: $blue-color;
          color: $white-color;
          border-radius: 5px;
          padding: 4px 8px 3px 8px;
          margin-left: 10px;
          @include media-breakpoint-down("xl") {
            margin-left: 5px;
          }
        }
      }
      .react-select-input {
        width: 300px;
        @include media-breakpoint-down("xxxl") {
          width: 250px;
        }
        @include media-breakpoint-down("xxl") {
          width: 220px;
        }
        @include media-breakpoint-down("md") {
          width: 150px;
        }
      }
    }
  }
}
