@import "~bootstrap/scss/bootstrap";
@import "./variables.module.scss";

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto-Bold.ttf");
  font-weight: 700;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  overflow-x: hidden;
  font-family: $font-roboto;
  font-weight: 400;
}

// Common Css
.invalid-feedback{
  font-size: 14px;
  font-weight: 400;
  @include media-breakpoint-down("xxl") {
    font-size: 12px;
  }
  @include media-breakpoint-down("xl") {
    margin-top: 2px;
    font-size: 11px;
  }
}
.btn {
  font-size: 16px;
  font-weight: 600;
  font-family: $font-poppins;
  padding: 12px 15px;
  border-radius: 10px;
  @include media-breakpoint-down("xxxl") {
    padding: 10px 15px;
  }
  @include media-breakpoint-down("xxl") {
    font-size: 14px;
  }
  @include media-breakpoint-down("xl") {
    padding: 7px 12px;
    font-size: 13px;
  }
  &.btn-sm {
    padding: 4px 12px 4px 12px;
    border-radius: 5px;
  }
  &.btn-lg {
    padding: 12px 40px 12px 40px;
    @include media-breakpoint-down("xxxl") {
      padding: 10px 30px 10px 30px;
    }
    @include media-breakpoint-down("xxl") {
      padding: 8px 25px 8px 25px;
    }
  }
  &.btn-primary {
    background-color: $green-color;
    color: $white-color;
    border-color: $green-color;
    &:active {
      background-color: $green-color;
      color: $white-color;
      border-color: $green-color;
    }
  }
  &.btn-outline-primary {
    background-color: transparent;
    color: $grey-color;
    border-color: $green-color;
  }
  &.btn-back {
    background-color: transparent;
    padding: 0;
    font-weight: 500;
    color: $light-grey-color;
    margin-right: 20px;
    border: none;
    img {
      margin-right: 10px;
      @include media-breakpoint-down("xxl") {
        width: 15px;
        margin-right: 6px;
        margin-top: 0;
      }
    }
  }
  .btn-img-left {
    margin-right: 2px;
    margin-top: -3px;
  }
  &.btn-white {
    background-color: $extra-light-blue-color;
    color: $primary-color;
    &:active {
      background-color: $extra-light-blue-color;
      color: $primary-color;
    }
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.form-group {
  position: relative;
  margin-bottom: 20px;
  @include media-breakpoint-down("xl") {
    margin-bottom: 12px;
  }
  label {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.21;
    color: $black-color;
    margin-bottom: 10px;
    @include media-breakpoint-down("xxxl") {
      font-size: 14px;
      margin-bottom: 6px;
    }
    @include media-breakpoint-down("xl") {
      font-size: 12px;
      margin-bottom: 3px;
    }
  }
  .form-control {
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid $light-blue-color;
    font-weight: 400;
    height: 52px;
    padding: 10px 20px;
    @include media-breakpoint-down("xxxl") {
      height: 45px;
      font-size: 14px;
      padding: 10px 15px;
    }
    @include media-breakpoint-down("xxl") {
      height: 42px;
    }
    @include media-breakpoint-down("xl") {
      height: 38px;
    }
    &::placeholder {
      color: $grey-color;
      font-weight: 400;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .input-img {
    position: absolute;
    top: 43px;
    right: 20px;
    @include media-breakpoint-down("xxxl") {
      top: 38px;
      right: 15px;
    }
    @include media-breakpoint-down("xxl") {
      top: 36px;
    }
    @include media-breakpoint-down("xl") {
      top: 34px;
    }
    img {
      @include media-breakpoint-down("xxxl") {
        width: 20px;
        height: 20px;
      }
      @include media-breakpoint-down("xl") {
        width: 17px;
        height: 17px;
      }
    }
  }
  .form-checkbox {
    .form-check {
      display: flex;
      align-items: center;
      .form-check-input {
        width: 28px;
        height: 28px;
        border: 2px solid $light-blue-color;
        border-radius: 5px;
        margin-top: 0;
        @include media-breakpoint-down("xxxl") {
          width: 25px;
          height: 25px;
        }
        &:checked {
          background-color: $blue-color;
          border-color: $blue-color;
        }
        &:focus {
          box-shadow: none;
        }
      }
      label {
        margin-left: 10px;
        margin-bottom: 0;
      }
    }
  }
}
.search-input {
  position: relative;
  .form-control {
    background-color: $extra-light-blue-color;
    border-color: $extra-light-blue-color;
    height: 50px;
    @include media-breakpoint-down("xxxl") {
      height: 42px;
    }
    @include media-breakpoint-down("xxl") {
      height: 40px;
    }
    @include media-breakpoint-down("xl") {
      height: 36px;
    }
  }
  img {
    position: absolute;
    right: 14px;
    top: 13px;
    @include media-breakpoint-down("xxxl") {
      top: 9px;
    }
    @include media-breakpoint-down("xxl") {
      width: 18px;
      height: 18px;
    }
  }
  [type="search"]::-webkit-search-cancel-button {
    appearance: none;
  }
}

.signin-form {
  h1 {
    font-size: 45px;
    font-weight: 600;
    line-height: 1.4;
    font-family: $font-poppins;
    margin-bottom: 10px;
    @include media-breakpoint-down("xxxl") {
      font-size: 40px;
    }
    @include media-breakpoint-down("xxl") {
      font-size: 32px;
      margin-bottom: 5px;
    }
    @include media-breakpoint-down("xl") {
      font-size: 28px;
    }
  }
  p {
    font-size: 18px;
    color: $grey-color;
    line-height: 1.17;
    margin-bottom: 30px;
    @include media-breakpoint-down("xxxl") {
      font-size: 16px;
      margin-bottom: 20px;
    }
    @include media-breakpoint-down("xxl") {
      font-size: 14px;
    }
    @include media-breakpoint-down("xl") {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }
}
.card {
  padding: 24px;
  background: $white-color;
  border: none;
  border-radius: 10px;
  @include media-breakpoint-down("xxxl") {
    padding: 20px;
  }
  @include media-breakpoint-down("xl") {
    padding: 15px;
  }
  .card-heading {
    font-size: 18px;
    font-weight: 500;
    color: $black-color;
    letter-spacing: 3px;
    text-transform: uppercase;
    @include media-breakpoint-down("xxxl") {
      font-size: 16px;
    }
    @include media-breakpoint-down("xxl") {
      font-size: 14px;
    }
    @include media-breakpoint-down("xl") {
      font-size: 13px;
    }
    span {
      background-color: $extra-light-blue-color;
      border: 1px solid $light-blue-color;
      border-radius: 5px;
      padding: 4px 9px 3px 9px;
      letter-spacing: 0;
    }
  }
  &.page-header {
    .card-heading {
      margin-right: 12px;
    }
    .react-select-input {
      width: 250px;
      @include media-breakpoint-down("xxxl") {
        width: 200px;
      }
      @include media-breakpoint-down("xxl") {
        width: 180px;
      }
    }
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 0;
  li {
    .page-link {
      display: inline-block;
      margin-right: 8px;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      background-color: $extra-light-blue-color;
      border: 1px solid $light-blue-color;
      border-radius: 5px;
      font-size: 16px;
      color: $primary-color;
      padding: 0;
    }
    &.active {
      .page-link {
        background-color: $primary-color;
        color: $white-color;
        border-color: $primary-color;
      }
    }
    &:first-child,
    &:last-child {
      position: relative;
      .page-link {
        color: transparent;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          background: url("/assets/images/arrow-active.svg") no-repeat center;
          z-index: 11;
          width: 9px;
          height: 13px;
        }
      }
    }
    &:first-child {
      .page-link {
        &::before {
          transform: rotate(-180deg);
        }
      }
      &.disabled {
        .page-link {
          &::before {
            transform: rotate(0deg);
            background: url("/assets/images/arrow-disabled.svg") no-repeat
              center;
          }
        }
      }
    }
    &:last-child {
      &.disabled {
        .page-link {
          &::before {
            background: url("/assets/images/arrow-disabled.svg") no-repeat
              center;
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
}
.custom-form-group {
  .upload-logo {
    .upload-logo-img {
      width: 200px;
      height: 200px;
      background-color: $extra-light-blue-color;
      border: 1px solid $light-blue-color;
      border-radius: 10px;
      margin-right: 20px;
      overflow: hidden;
      @include media-breakpoint-down("xxxl") {
        width: 150px;
        height: 150px;
      }
      @include media-breakpoint-down("xxl") {
        width: 130px;
        height: 130px;
        margin-right: 12px;
      }
      img {
        width: 100%;
        height:auto;
        &.dummy-logo{
          width: auto;
          height: auto;
        }
      }
    }
    .upload-logo-text {
      p {
        font-size: 16px;
        font-weight: 500;
        color: $blue-color;
        margin-bottom: 5px;
        @include media-breakpoint-down("xxxl") {
          font-size: 14px;
        }
      }
      span {
        font-size: 12px;
        font-weight: 500;
        color: $grey-color;
      }
    }
  }
}
.custom-tabs {
  &.custom-tabs-3 {
    .nav-tabs {
      width: 850px;
      @include media-breakpoint-down("xxxl") {
        width: 750px;
      }
      @include media-breakpoint-down("xxl") {
        width: 600px;
      }
      @include media-breakpoint-down("xl") {
        width: 500px;
      }
      @include media-breakpoint-down("md") {
        width: 100%;
      }
      .nav-item {
        width: 33.33%;
      }
    }
  }
  .nav-tabs {
    background-color: $extra-light-blue-color;
    border: 1px solid $light-blue-color;
    border-radius: 10px;
    padding: 5px;
    width: 570px;
    margin: 0 auto 50px;
    @include media-breakpoint-down("xxl") {
      width: 450px;
    }
    @include media-breakpoint-down("xl") {
      width: 380px;
      padding: 4px;
      margin-bottom: 30px;
    }
    .nav-item {
      width: 50%;
      position: relative;
      .nav-link {
        font-size: 16px;
        font-weight: 500;
        font-family: $font-poppins;
        color: $primary-color;
        padding: 10px 20px;
        width: 100%;
        border: none;
        border-radius: 10px;
        @include media-breakpoint-down("xxl") {
          font-size: 14px;
          padding: 8px 20px;
        }
        @include media-breakpoint-down("xl") {
          font-size: 12px;
          padding: 7px 15px;
        }
        &.active {
          background-color: $primary-color;
          color: $white-color;
        }
      }
    }
  }
  form {
    max-width: 570px;
    margin: 0 auto;
    @include media-breakpoint-down("xxl") {
      max-width: 450px;
    }
    @include media-breakpoint-down("xl") {
      max-width: 380px;
    }
  }
}
.react-select-wrapper {
  margin-right: 24px;
  span {
    font-size: 16px;
    font-weight: 500;
    color: $black-color;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: 12px;
    @include media-breakpoint-down("xxl") {
      font-size: 14px;
      margin-right: 7px;
    }
  }
}
.react-select-container {
  .react-select {
    &__control {
      font-size: 16px;
      color: $grey-color !important;
      background-color: $white-color;
      font-weight: 500;
      padding: 6px 12px 6px 15px;
      box-shadow: none;
      &:hover {
        border-color: $light-blue-color;
      }
      border-radius: 10px;
      border-color: $light-blue-color;
      min-height: 50px;
      height: auto;
      @include media-breakpoint-down("xxxl") {
        min-height: 45px;
        font-size: 14px;
      }
      @include media-breakpoint-down("xxl") {
        min-height: 42px;
      }
      @include media-breakpoint-down("xl") {
        min-height: 38px;
      }
      &:focus {
        box-shadow: none;
        outline: none;
      }
      &:focus-visible {
        box-shadow: none;
        outline: none;
      }
      &.css-t3ipsp-control {
        box-shadow: none;
        outline: none;
      }
      &--menu-is-open {
        border-radius: 10px 10px 0 0;
        border-color: $light-blue-color;
        box-shadow: none;
      }
    }
    &__single-value {
      color: $grey-color;
    }
    &__indicator-separator {
      display: none;
    }
    &__input-container {
      margin: 0;
      padding: 0;
      color: $grey-color;
    }
    &__placeholder {
      color: $grey-color;
    }
    &__menu {
      border: 1px solid $light-blue-color !important;
      padding: 0;
      background: $white-color;
      color: $grey-color;
      width: 100%;
      margin: 0 auto;
      border-radius: 0 0 10px 10px;
      display: block;
      box-shadow: none;
    }
    &__menu-list {
      max-height: 150px;
      overflow-y: auto;
    }
    &__option {
      font-weight: 500;
      background-color: $white-color;
      color: $grey-color;
      &--is-focused {
        background-color: $extra-light-blue-color;
        color: $grey-color;
        cursor: pointer;
      }
      &:active {
        background-color: $extra-light-blue-color;
        color: $grey-color;
        cursor: pointer;
      }
      @include media-breakpoint-down("xxxl") {
        font-size: 14px;
      }
    }
    &__indicator {
      color: $blue-color;
      padding: 0 0 0 5px;
      cursor: pointer;
      &:hover {
        color: $blue-color;
      }
    }
    &__value-container {
      padding: 0;
    }
    &__multi-value {
      background-color: $extra-light-blue-color;
      &__label {
        color: $grey-color;
      }
      &__remove {
        &:hover {
          background-color: $light-blue-color;
          color: $primary-color;
        }
      }
    }
  }
}
.menuplacement-top {
  .react-select-container {
    .react-select {
      &__control {
        &--menu-is-open {
          border-radius: 0 0 10px 10px;
        }
      }
      &__menu {
        border-radius: 10px 10px 0 0;
      }
    }
  }
}
.header-switch {
  span {
    font-size: 16px;
    color: $black-color;
    font-weight: 500;
    margin-right: 10px;
    @include media-breakpoint-down("xxl") {
      font-size: 14px;
    }
  }
}
.action-btn {
  display: flex;
  gap: 22px;
  @include media-breakpoint-down("xxl") {
    gap: 15px;
  }
  img {
    @include media-breakpoint-down("xxl") {
      width: 13px;
    }
  }
}
.btn-link {
  font-size: 16px;
  font-weight: 500;
  color: $light-grey-color;
  text-decoration: none;
  @include media-breakpoint-down("xxxl") {
    font-size: 14px;
  }
  img {
    margin-left: 10px;
    @include media-breakpoint-down("xxxl") {
      width: 24px;
      height: 24px;
    }
  }
  &:hover {
    color: $light-grey-color;
  }
}
.blue-color {
  color: $blue-color !important;
}
.border-bottom-left-radius {
  border-bottom-left-radius: 0;
}
.border-bottom-right-radius {
  border-bottom-right-radius: 0;
}
.border-top-left-radius {
  border-top-left-radius: 0;
}
.border-top-right-radius {
  border-top-right-radius: 0;
}
.col-space-10 {
  padding-right: 10px;
  padding-left: 10px;
}
.react-select-input-date .react-datepicker__input-container input, .react-select-input-date .react-datepicker__input-container input:focus {
  padding: 10px 5px;
  border-radius: 8px;
  border: 1px solid #d2e7f2;
  max-width: 200px;
  @include media-breakpoint-down("xxl") {
    width: 120px;      
  }
}