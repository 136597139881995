@import "/src/variables.module.scss";
footer {
  padding: 20px 30px;
  bottom: 20px;
  left: 30px;
  right: 30px;
  background-color: $extra-light-blue-color;
  @include media-breakpoint-down("xxxl") {
    padding: 15px 20px;
  }
  @include media-breakpoint-down("xl") {
    padding: 10px 20px;
  }
  .footer-copright {
    font-size: 14px;
    color: $light-grey-color;
    @include media-breakpoint-down("xl") {
      font-size: 12px;
    }
    @include media-breakpoint-down("lg") {
      text-align: center;
      margin-bottom: 7px;
    }
  }
  .footer-links {
    ul {
      @include media-breakpoint-down("lg") {
        justify-content: center;
      }
      li {
        padding: 0 10px;
        list-style-type: none;
        border-right: 1px solid $light-blue-color;
        line-height: 1;
        &:last-child {
          border-right: 0;
          padding-right: 0;
        }
        &:first-child {
          padding-left: 0;
        }
        a {
          font-size: 14px;
          color: $blue-color;
          text-decoration: none;
          @include media-breakpoint-down("xl") {
            font-size: 12px;
          }
        }
      }
    }
  }
}
