@import "/src/variables.module.scss";

.signin-form {
  margin: 0 auto;
  > div {
    width: 570px;
    @include media-breakpoint-down("xxl") {
      width: 450px;
    }
    .reset-password{
      font-size: 14px;
      font-weight: 500;
      color: $black-color;
      @include media-breakpoint-down("xxl") {
        font-size: 12px;
      }
      a{
        color: $blue-color;
        text-decoration: none;
        margin-left: 5px;
      }
    }
  }
}
