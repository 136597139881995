@import "/src/variables.module.scss";
.edit-site-card {
  max-height: calc(100vh - 225px);
  overflow-y: auto;
  @include media-breakpoint-down("xxxl") {
    max-height: calc(100vh - 185px);
  }
  @include media-breakpoint-down("xxl") {
    max-height: calc(100vh - 172px);
  }
  @include media-breakpoint-down("xl") {
    max-height: calc(100vh - 143px);
  }
  @include media-breakpoint-down("lg") {
    max-height: calc(100vh - 150px);
  }
  &:hover {
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: $white-color;
    }
    &::-webkit-scrollbar-thumb {
      background: $light-blue-color;
    }
  }
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
    margin-top: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  .dashboard-map {
    position: sticky;
    top: 0;
    height: calc(100vh - 273px);
    overflow: hidden;
    @include media-breakpoint-down("xxxl") {
      height: calc(100vh - 225px);
    }
    @include media-breakpoint-down("xxl") {
      height: calc(100vh - 215px);
    }
    @include media-breakpoint-down("xl") {
      height: calc(100vh - 173px);
    }
    @include media-breakpoint-down("lg") {
      height: 300px;
      margin-bottom: 20px;
    }
  }
}
.add-alarm {
  background-color: $primary-color;
  border-radius: 10px;
  padding: 9px 9px 9px 20px;
  span {
    color: $white-color;
    font-weight: 500;
    @include media-breakpoint-down("xxxl") {
      font-size: 14px;
    }
  }
}
.site-detail {
  list-style-type: none;
  li {
    margin-bottom: 15px;
    @include media-breakpoint-down("xxxl") {
      font-size: 14px;
    }
    @include media-breakpoint-down("xl") {
      font-size: 12px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    p {
      width: 250px;
      font-weight: 500;
      color: $black-color;
    }
    span {
      color: $grey-color;
    }
  }
}
