@import "/src/variables.module.scss";

.privacy-content {
    margin: 100px auto;
    .privacy-section {
    .heading {
      color: $blue-color;
    }
    .content {
      font-size: 13.5pt;
      b {
        font-size: 14pt;
      }
    }
  }
}
