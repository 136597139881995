@import "/src/variables.module.scss";
.customer-sites-detail {
  .dashboard-detail {
    .dashboard-table {
      max-height: calc(100vh - 663px);
      @include media-breakpoint-down("xxxl") {
        max-height: calc(100vh - 570px);
      }
      @include media-breakpoint-down("xxl") {
        max-height: calc(100vh - 541px);
      }
      @include media-breakpoint-down("xl") {
        max-height: calc(100vh - 470px);
      }
    }
    .dashboard-table-lg {
      .card {
        max-height: calc(100vh - 663px);
        @include media-breakpoint-down("xxxl") {
          max-height: calc(100vh - 570px);
        }
        @include media-breakpoint-down("xxl") {
          max-height: calc(100vh - 541px);
        }
        @include media-breakpoint-down("xl") {
          max-height: calc(100vh - 470px);
        }
      }
    }
  }
}
