@import "/src/variables.module.scss";
.card {
  &.page-header {
    .search-input {
      width: 300px;
      @include media-breakpoint-down("xxl") {
        width: 250px;
      }
      @include media-breakpoint-down("xl") {
        width: 220px;
      }
    }
  }
}
