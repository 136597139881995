@import "/src/variables.module.scss";
.main-logo {
  padding: 30px 0 20px;
  @include media-breakpoint-down("xxl") {
    padding: 40px 0 20px;
  }
  @include media-breakpoint-down("xl") {
    padding: 30px 0 10px;
  }
  img {
    height: 80px;
    @include media-breakpoint-down("xxxl") {
      height: 60px;
    }
  }
  &.main-logo-footer{
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    @include media-breakpoint-down("xxxl") {
      bottom: 80px;
    }
    @include media-breakpoint-down("xxl") {
      bottom: 70px;
    }
    @include media-breakpoint-down("xl") {
      bottom: 60px;
    }
    @include media-breakpoint-down("lg") {
      bottom: 85px;
    }
    img{
      height: 60px;
      @include media-breakpoint-down("xxl") {
        height: 50px;
      }
      @include media-breakpoint-down("xl") {
        height: 40px;
      }
    }
  }
}
.splash-image {
  img {
    max-width: 100%;
    @include media-breakpoint-down("lg") {
      max-width: 130%;
      width: 130%;
      margin-left: -19%;
    }
    @include media-breakpoint-down("md") {
      max-width: 200%;
      width: 200%;
      margin-left: -50%;
    }
  }
}
.splash-screen{
  &.hide{
    opacity: 0;
    height: 0;
    transition: all 1s;
  }
}
