$primary-color: #1f3e50;
$dark-color: #074464;
$black-color: #000;
$white-color: #ffffff;
$green-color: #8cc244;
$blue-color: #29aae2;
$light-blue-color: #d2e7f2;
$extra-light-blue-color: #F2F8FF;
$grey-color: rgba(0, 0, 0, 0.7);
$light-grey-color: rgba(0, 0, 0, 0.5);
$extra-light-grey-color: rgba(0, 0, 0, 0.05);


$font-roboto: "Roboto";
$font-poppins: "Poppins";

$grid-breakpoints: (
  xxs: 370px,
  xs: 576px,
  sm: 767px,
  md: 991px,
  lg: 1200px,
  xl: 1400px,
  xxl: 1600px,
  xxxl: 1800px,
);

@function get-breakpoint($name) {
  @return map-get($grid-breakpoints, $name);
}

@mixin media-breakpoint-down($name) {
  $breakpoint: get-breakpoint($name);
  @media (max-width: $breakpoint) {
    @content;
  }
}

