@import "/src/variables.module.scss";

.signin-wrapper {
  &.hide{
    opacity: 0;
  }
  &.show{
    opacity: 1;
  }
  .signin-section {
    margin-top: 20px;
    &.signin-section-admin{
      margin-top: 40px;
    }
    @include media-breakpoint-down("lg") {
      height: calc(100vh - 235px);
      align-items: center;
      margin-top: 0;
    }
    @include media-breakpoint-down("md") {
      height: calc(100vh - 250px);
    }
    
    .signin-img {
      max-width: 60%;
      flex: 0 0 60%;
      @include media-breakpoint-down("xxxl") {
        margin-left: -100px;
      }
    }
    .signin-form {
      max-width: 30%;
      flex: 0 0 30%;
      position: relative;
      z-index: 2;
      &.admin-signin-form{
        @include media-breakpoint-down("xxl") {
          margin-top: 0px;
        }
      }
      @include media-breakpoint-down("xxl") {
        margin-top: -50px;
      }
      @include media-breakpoint-down("lg") {
        max-width: 100%;
        flex: 0 0 100%;
        margin-top: 0;
      }
      > div {
        background-color: $white-color;
        border-radius: 10px;
        box-shadow: 0 20px 25px #0938510d;
        padding: 30px 50px 50px;
        width: 600px;
        @include media-breakpoint-down("xxxl") {
          width: 500px;
          padding: 30px 40px;
        }
        @include media-breakpoint-down("xxl") {
          width: 480px;
          padding: 20px 30px 30px;
        }
        @include media-breakpoint-down("xl") {
          width: 430px;
        }
        @include media-breakpoint-down("lg") {
          width: 400px;
          margin: auto;
        }
        .forgot-password {
          font-weight: 500;
          text-decoration: none;
          @include media-breakpoint-down("xxxl") {
            font-size: 14px;
          }
        }
      }
    }
  }
}
