@import "/src/variables.module.scss";
.modal {
  .modal-dialog {
    min-width: 618px;
    @include media-breakpoint-down("xxl") {
      min-width: 550px;
    }
    @include media-breakpoint-down("xl") {
      min-width: 500px;
    }
    .modal-content {     
      border-radius: 10px;
      overflow: hidden;
      .modal-header {
        padding: 16px 24px;
        background-color: $light-blue-color;
        @include media-breakpoint-down("xxxl") {
          padding: 10px 20px;
        }
        h4 {
          font-size: 20px;
          font-weight: 500;
          color: $black-color;
          @include media-breakpoint-down("xxxl") {
            font-size: 18px;
          }
          @include media-breakpoint-down("xxl") {
            font-size: 16px;
          }
          @include media-breakpoint-down("xl") {
            font-size: 14px;
          }
        }
        .btn-close {
          width: 40px;
          height: 40px;
          background: none;
          @include media-breakpoint-down("xxxl") {
            width: 30px;
            height: 30px;
          }
          img{
            @include media-breakpoint-down("xxxl") {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
      .modal-body {
        padding: 24px;
        @include media-breakpoint-down("xxxl") {
          padding: 20px;
        }
        .modal-des {
          font-size: 16px;
          font-weight: 400;
          color: $grey-color;
          margin-bottom: 20px;
          line-height: 1.5;
          @include media-breakpoint-down("xl") {
            font-size: 14px;
          }
        }
      }
    }
  }
}
