@import "/src/variables.module.scss";
.floor-detail-card {
  ul {
    list-style-type: none;
    li {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
      @include media-breakpoint-down("xl") {
        margin-bottom: 12px;
      }
      p {
        font-weight: 400;
        margin-bottom: 0;
        color: $grey-color;
        @include media-breakpoint-down("xxl") {
          font-size: 14px;
        }
        span {
          font-weight: 500;
          min-width: 160px;
          display: inline-flex;
          color: $black-color;
        }
      }
    }
  }
}
.allowcated-sites {
  border: 1px solid $light-blue-color;
  margin: 30px 0 0;
  .custom-table {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    max-height: calc(100vh - 583px);
    overflow-y: auto;
    @include media-breakpoint-down("xxxl") {
      max-height: calc(100vh - 524px);
    }
    @include media-breakpoint-down("xxl") {
      max-height: calc(100vh - 494px);
    }
    @include media-breakpoint-down("xl") {
      max-height: calc(100vh - 411px);
    }
    @include media-breakpoint-down("lg") {
      max-height: calc(100vh - 434px);
    }
    &:hover {
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        background-color: $white-color;
      }
      &::-webkit-scrollbar-thumb {
        background: $light-blue-color;
      }
    }
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
      margin-top: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 10px;
    }
  }
}
