@import "/src/variables.module.scss";
.nav-notification {
  .notification-open {
    position: absolute;
    top: 56px;
    right: -60px;
    @include media-breakpoint-down("xxxl") {
      top: 47px;
    }
    @include media-breakpoint-down("xxl") {
      top: 42px;
    }
    @include media-breakpoint-down("xl") {
      top: 36px;
    }
    .card {
      position: relative;
      width: 400px;
      border: 2px solid $light-blue-color;
      @include media-breakpoint-down("xxl") {
        width: 350px;
      }
      @include media-breakpoint-down("xl") {
        width: 320px;
      }
      &:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid white;
        position: absolute;
        top: -10px;
        right: 62px;
        @include media-breakpoint-down("xxxl") {
          right: 60px;
        }
        @include media-breakpoint-down("xl") {
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 7px solid white;
          top: -7px;
        }
      }
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid $light-blue-color;
        position: absolute;
        top: -13px;
        right: 52px;
        z-index: -1;
        @include media-breakpoint-down("xxxl") {
          right: 50px;
        }
        @include media-breakpoint-down("xl") {
          border-left: 17px solid transparent;
          border-right: 17px solid transparent;
          border-bottom: 17px solid $light-blue-color;
          top: -10px;
        }
      }
      .notification-header {
        margin-bottom: 23px;
        @include media-breakpoint-down("xxxl") {
          margin-bottom: 15px;
        }
        p {
          color: $black-color;
          font-size: 20px;
          font-weight: 500;
          @include media-breakpoint-down("xxxl") {
            font-size: 18px;
          }
          @include media-breakpoint-down("xxl") {
            font-size: 16px;
          }
          @include media-breakpoint-down("xl") {
            font-size: 14px;
          }
        }
        a {
          font-size: 16px;
          font-weight: 500;
          text-decoration: none;
          color: $blue-color;
          @include media-breakpoint-down("xxl") {
            font-size: 14px;
          }
          @include media-breakpoint-down("xl") {
            font-size: 13px;
          }
        }
      }
      .notification-list {
        max-height: 300px;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        li {
          padding: 14px 0;
          border-top: 1px solid $extra-light-grey-color;
          @include media-breakpoint-down("xxl") {
            padding: 10px 0;
          }
          @include media-breakpoint-down("xl") {
            padding: 7px 0;
          }
          &.pending-notify {
            position: relative;
            padding-right: 20px;
            &:before {
              content: "";
              position: absolute;
              top: 20px;
              right: 0;
              width: 10px;
              height: 10px;
              border-radius: 20px;
              background-color: $green-color;
              @include media-breakpoint-down("xxl") {
                width: 8px;
                height: 8px;
              }
              @include media-breakpoint-down("xl") {
                top: 15px;
              }
            }
          }
          p {
            font-size: 16px;
            line-height: 1.5;
            color: $grey-color;
            margin-bottom: 5px;
            @include media-breakpoint-down("xxl") {
              font-size: 14px;
            }
            @include media-breakpoint-down("xl") {
              font-size: 12px;
            }
            span {
              font-size: 16px;
              color: $black-color;
              font-weight: 500;
              @include media-breakpoint-down("xxl") {
                font-size: 14px;
              }
              @include media-breakpoint-down("xl") {
                font-size: 12px;
              }
            }
          }
          span {
            font-size: 14px;
            font-weight: 500;
            color: $primary-color;
            @include media-breakpoint-down("xxl") {
              font-size: 13px;
            }
            @include media-breakpoint-down("xl") {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}
